<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <img
            :src="$store.state.user.photoURL || 'https://i2.wp.com/www.eatthis.com/wp-content/uploads/2018/09/waffle-on-plate.jpg'"
            class="c-avatar-img "
          />
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownItem v-if="$store.state.user.name">
      {{ $store.state.user.name }}
    </CDropdownItem>
    <CDropdownItem @click="logOut">
      <CIcon name="cil-lock-locked" /> Logout
    </CDropdownItem>
  </CDropdown>
</template>

<script>
export default {
  name: 'TheHeaderDropdownAccnt',
  methods: {
    logOut() {
      this.$store.dispatch('user/logout');
      this.$router.push({ name: 'Login' });
    }
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>