export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Home',
        to: '/',
        icon: 'cil-speedometer'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Categories',
        to: '/cats',
        icon: 'cil-location-pin'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Bulk',
        to: '/bulk',
        icon: 'cil-library-add'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Brands',
        to: '/brands',
        icon: 'cil-people'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Props',
        to: '/props',
        icon: 'cil-layers'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Users',
        to: '/users',
        icon: 'cil-people'
      }
    ]
  }
]